import { ClockIcon } from "@heroicons/react/24/outline";
import { useMediaQuery } from "@mui/material";
import axios from "axios";
import { Link } from "gatsby";
import React, { useEffect, useState } from "react";

type pinObject = {
  uuid: string;
};

export const Pinneds = ({ uuid }: pinObject) => {
  const [item, setItem] = useState<any>();
  const matches = useMediaQuery("(max-width: 1280px)");

  useEffect(() => {
    axios
      .get(
        process.env.API_URL +
          `/api/info/algolia/search?query=${uuid}&limit=12&page=0`
      )
      .then((response) => {
        setItem(response?.data?.data?.hits[0]);
      })
      .catch(function (error) {
        console.log("[DEBUG]", error);
      });
  }, []);
  const calculateLenghtLetter = () => {
    return matches ? 80 : 70;
  };

  return (
    <div className="min-w-[80%] md:min-w-[45%]  lg:min-w-fit lg:col-span-4">
      {item !== undefined && (
        <Link
          to={`/course/${item.slug}`}
          state={{ id: item.title, course: item }}
        >
          <div className="relative">
            <div className="before:bg-black before:absolute before:top-0 before:bottom-0 before:left-0 before:right-0 before:rounded-3xl before:opacity-50"></div>
            <img
              className="object-cover w-full h-[250px] rounded-3xl bg-slate-300"
              src={item.imgUrl}
              alt=""
            />
            <div className="absolute w-full h-full z-100 flex items-start justify-center pt-[35px] pl-[35px] pr-[35px] pb-[70px] top-0 flex-col">
              <p className="ff-cg--semibold text-white text-[14px] mb-[8px]">
                In Partnership With:
              </p>
              <img
                className="w-auto object-cover h-12"
                src={item.sponsor.imgUrl}
                alt=""
              />
            </div>
          </div>
          <div className="rounded-3xl bg-white p-[30px] flex flex-col justify-between max-h-[400px] xl:max-h-[400px] mt-[-30px] shadow-lg relative">
            <div>
              <div className="flex flex-wrap h-[41px] overflox-hidden items-end gap-1 mb-[10px]">
                {item.categories.map((category: any, index: number) => {
                  return (
                    <span
                      className="flex items-center text-[#da1a32] border border-[#da1a32] rounded-full pl-[10px] pr-[10px] mr-2"
                      key={index}
                    >
                      <span className="ff-cg--semibold text-[12px]">
                        {category.name}
                      </span>
                    </span>
                  );
                })}
              </div>
              <h4 className="flex items-end text-[20px] lg:text-[24px] xl:text-[20px] h-[40px] ff-cg--semibold leading-none mb-[10px] overflow-hidden">
                {item.title?.length > calculateLenghtLetter()
                  ? item.title?.substring(0, calculateLenghtLetter()) + "..."
                  : item.title}
              </h4>
              <p className="h-[48px] overflow-hidden">
                {item.description > 120
                  ? item.description.substring(0, 120) + "..."
                  : item.description}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <p className="ff-cg--semibold text-[20px]">
                {Number(item.price).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  currencyDisplay: "symbol",
                })}
              </p>
              <span className="flex items-center text-[#da1a32] border border-[#da1a32] rounded-full pl-[3px] pr-[10px]">
                <ClockIcon className="h-4 w-4 mr-[6px]" />
                <span className="ff-cg--semibold text-[12px]">
                  {item.duration}
                </span>
              </span>
            </div>
          </div>
        </Link>
        // <>
        //   {item.type.name === "Course" ? (
        //     <a
        //       href={`/courses/${item.slug}`}
        //       // to={`/courses/${item.slug}`}
        //       // state={{ id: item.title, course: item }}
        //       target="_blank"
        //     >
        //       <div className="relative">
        //         <div className="before:bg-black before:absolute before:top-0 before:bottom-0 before:left-0 before:right-0 before:rounded-3xl before:opacity-50"></div>
        //         <img
        //           className="object-cover w-full h-[250px] rounded-3xl bg-slate-300"
        //           src={item.imgUrl}
        //           alt=""
        //         />
        //         <div className="absolute w-full h-full z-100 flex items-start justify-center pt-[35px] pl-[35px] pr-[35px] pb-[70px] top-0 flex-col">
        //           <p className="ff-cg--semibold text-white text-[14px] mb-[8px]">
        //             In Partnership With:a
        //           </p>
        //           <img
        //             className="w-auto object-cover h-12"
        //             src={item.sponsor.imgUrl}
        //             alt=""
        //           />
        //         </div>
        //       </div>
        //       <div className="rounded-3xl bg-white p-[30px] flex flex-col justify-between h-[300px] mt-[-30px] shadow-lg relative">
        //         <div>
        //           <div className="flex flex-wrap items-center gap-2 mb-[10px]">
        //             {item.categories.map((category: any, index: number) => {
        //               return (
        //                 <span
        //                   className="flex items-center text-[#da1a32] border border-[#da1a32] rounded-full pl-[10px] pr-[10px] mr-2"
        //                   key={index}
        //                 >
        //                   <span className="ff-cg--semibold text-[12px]">
        //                     {category.name}
        //                   </span>
        //                 </span>
        //               );
        //             })}
        //           </div>
        //           <h4 className="text-[20px] lg:text-[40px] ff-cg--semibold leading-none mb-[10px] overflow-hidden">
        //             {item.title}
        //           </h4>
        //           <p className="max-h-[75px] overflow-hidden">
        //             {item.description > 130
        //               ? item.description.substring(0, 130) + "..."
        //               : item.description}
        //           </p>
        //         </div>
        //         <div className="flex items-center justify-between">
        //           <p className="ff-cg--semibold text-[20px]">
        //             {Number(item.price).toLocaleString("en-US", {
        //               style: "currency",
        //               currency: "USD",
        //               currencyDisplay: "symbol",
        //             })}
        //           </p>
        //           <span className="flex items-center text-[#da1a32] border border-[#da1a32] rounded-full pl-[3px] pr-[10px]">
        //             <ClockIcon className="h-4 w-4 mr-[6px]" />
        //             <span className="ff-cg--semibold text-[12px]">
        //               {item.duration}
        //             </span>
        //           </span>
        //         </div>
        //       </div>
        //     </a>
        //   ) : (
        //     <a
        //       href={`/paths/${item.slug}`}
        //       // to={`/paths/${item.slug}`}
        //       // state={{ id: item.title, course: item }}
        //       target="_blank"
        //     >
        //       <div className="relative">
        //         <div className="before:bg-black before:absolute before:top-0 before:bottom-0 before:left-0 before:right-0 before:rounded-3xl before:opacity-50"></div>
        //         <img
        //           className="object-cover w-full h-[250px] rounded-3xl bg-slate-300"
        //           src={item.imgUrl}
        //           alt=""
        //         />
        //         <div className="absolute w-full h-full z-100 flex items-start justify-center pt-[35px] pl-[35px] pr-[35px] pb-[70px] top-0 flex-col">
        //           <p className="ff-cg--semibold text-white text-[14px] mb-[8px]">
        //             In Partnership With:
        //           </p>
        //           <img
        //             className="w-auto object-cover h-12"
        //             src={item.sponsor.imgUrl}
        //             alt=""
        //           />
        //         </div>
        //       </div>
        //       <div className="rounded-3xl bg-white p-[30px] flex flex-col justify-between h-[300px] mt-[-30px] shadow-lg relative">
        //         <div>
        //           <div className="flex flex-wrap items-center gap-2 mb-[10px]">
        //             {item.categories.map((category: any, index: number) => {
        //               return (
        //                 <span
        //                   className="flex items-center text-[#da1a32] border border-[#da1a32] rounded-full pl-[10px] pr-[10px] mr-2"
        //                   key={index}
        //                 >
        //                   <span className="ff-cg--semibold text-[12px]">
        //                     {category.name}
        //                   </span>
        //                 </span>
        //               );
        //             })}
        //           </div>
        //           <h4 className="text-[20px] lg:text-[40px] ff-cg--semibold leading-none mb-[10px]">
        //             {item.title}
        //           </h4>
        //           <p>{item.description}</p>
        //         </div>
        //         <div className="flex items-center justify-between">
        //           <p className="ff-cg--semibold text-[20px]">
        //             {Number(item.price).toLocaleString("en-US", {
        //               style: "currency",
        //               currency: "USD",
        //               currencyDisplay: "symbol",
        //             })}
        //           </p>
        //           <span className="flex items-center text-[#da1a32] border border-[#da1a32] rounded-full pl-[3px] pr-[10px]">
        //             <ClockIcon className="h-4 w-4 mr-[6px]" />
        //             <span className="ff-cg--semibold text-[12px]">
        //               {item.duration}
        //             </span>
        //           </span>
        //         </div>
        //       </div>
        //     </a>
        //   )}
        // </>
      )}
    </div>
  );
};